import { darken } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { flexRowNoWrap } from 'theme/styles'
import { HoneypotDetails } from '../../honeypot/honeypotService'

interface TokenHoneypotDetailsProps {
  honeypotDetails: HoneypotDetails | null
  isInputToken: boolean
}

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.neutral2};
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 6px;
  width: 100%;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.neutral2)};
  }
`

const HoneypotDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 8px;
`

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between; /* Ensures space between left and right sections */
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping of items if necessary */
`

const DetailGroupLeft = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const DetailGroupRight = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-left: auto; /* Pushes this group to the right */
`

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-shrink: 0; /* Prevents shrinking */
`

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

const TaxText = styled(ThemedText.BodySmall)<{ isHighTax: boolean }>`
  color: ${({ isHighTax, theme }) => (isHighTax ? theme.critical : theme.text)};
`

const TokenHoneypotDetails: React.FC<TokenHoneypotDetailsProps> = ({ honeypotDetails, isInputToken }) => {
  if (!honeypotDetails) {
    return null
  }

  const riskLevel = honeypotDetails.summary?.riskLevel ?? 0
  const isHoneypot = honeypotDetails.honeypotResult?.isHoneypot
  const getColorForHoneypot = isHoneypot ? 'red' : 'green'

  return (
    <HoneypotDetailsContainer>
      <LabelRow>Token Security Simulation</LabelRow>
      <DetailsRow>
        <DetailGroupLeft>
          <ThemedText.BodyPrimary>Tax:</ThemedText.BodyPrimary>
          <DetailItem>
            <TaxText isHighTax={(honeypotDetails.simulationResult?.buyTax ?? 0) > 9}>
              B: {honeypotDetails.simulationResult?.buyTax ?? 'N/A'}%
            </TaxText>
          </DetailItem>
          <DetailItem>
            <TaxText isHighTax={(honeypotDetails.simulationResult?.sellTax ?? 0) > 9}>
              S: {honeypotDetails.simulationResult?.sellTax ?? 'N/A'}%
            </TaxText>
          </DetailItem>
          <DetailItem>
            <TaxText isHighTax={(honeypotDetails.simulationResult?.transferTax ?? 0) > 9}>
              T: {honeypotDetails.simulationResult?.transferTax ?? 'N/A'}%
            </TaxText>
          </DetailItem>
        </DetailGroupLeft>
        <DetailGroupRight>
          <DetailItem>
            <ThemedText.BodySmall>Honeypot:</ThemedText.BodySmall>
            <Dot color={getColorForHoneypot} />
          </DetailItem>
          <DetailItem>
            <ThemedText.BodySmall>Safety:</ThemedText.BodySmall>{' '}
            <Dot color={riskLevel === 1 ? 'green' : riskLevel === 100 ? 'red' : 'yellow'} />
          </DetailItem>
        </DetailGroupRight>
      </DetailsRow>
    </HoneypotDetailsContainer>
  )
}

export default TokenHoneypotDetails
